import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import WhatsappSubscription from './WhatsappSubscription.vue'
import WhatsappConfirmSubscription from './WhatsappConfirmSubscription.vue'

export default {
  name: 'SubscriptionNeeded',
  props: {
    payment: {
      type: Object,
      required: true,
    },
  },
  components: {
    HeaderTopDashboard,
    WhatsappSubscription,
    WhatsappConfirmSubscription,
  },
  data () {
    return {
      ready: false,
    }
  },
  computed: {
  },
  methods: {
  },
  created () {
  },
  mounted () {
  },
}
