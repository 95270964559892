import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import SubscriptionPlanService from '@/services/subscription-plan.service'
import { Subscriptions } from '@/classes/helpers/Subscriptions'
import PaymentService from '@/services/payment.service'
import { getParameterByName } from '@/util/GetQueryString.js'
import EventBus from '@/util/EventBus'
import browserCache from '@/services/browser-cache'
import getSymbolFromCurrency from 'currency-symbol-map'
import moment from 'moment'
import routesService from '../../../../services/whatsapp/routes.service'

export default {
  name: 'WhatsappSubscription',
  components: {
    HeaderTopDashboard,
  },
  props: {
    payment: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      currencySymbol: getSymbolFromCurrency(browserCache.getJson('user').currencyCode),
      setup: 0,
      fee: 0,
      isAdditional: null,
    }
  },
  computed: {
    hasProportionalPayment () {
      const daysTilMonthEnd = moment().daysInMonth() - moment().date() + 1
      return daysTilMonthEnd < moment().daysInMonth()
    },
    momentDaysInMonth () {
      return moment().daysInMonth()
    },
    momentDate () {
      return moment().date()
    },
    proportionalPayment () {
      const daysTilMonthEnd = moment().daysInMonth() - moment().date() + 1
      return (this.fee / moment().daysInMonth()) * daysTilMonthEnd
    },
  },
  methods: {
    getPaymentMethods () {
      this.payment.billingProfiles = true
      PaymentService.getPaymentMethods()
        .then(
          (response) => {
            this.payment.activeCreditCard = response.activeCreditCard
            this.payment.activeMultibanco = response.activeMultibanco
            this.payment.activeBoleto = response.activeBoleto
            this.payment.activePaypal = response.activePaypal
            this.payment.activePlan = response.activePlan
            this.payment.activeSubscription = response.activeSubscription
            this.payment.activeTransfer = response.activeTransfer
            this.payment.billingProfiles = response.billingProfiles
            this.payment.currency = response.currency
            this.payment.fromPlans = response.fromPlans
            this.payment.paymentsInfo = response.paymentsInfo
            this.payment.show = true
            this.getPaymentInvoice()
            this.getCreditCardPaymentMethod()
          },
          () => {},
        )
        .finally(() => {})
    },
    getPaymentInvoice () {
      const token = getParameterByName('token')
      const paymentMethod = getParameterByName('paymentMethod')

      if (token && (parseInt(paymentMethod) === this.payment.paymentMethod.creditCard)) {
        this.getPaymentInvoiceCreditCard(token)
      }
    },
    getPaymentInvoiceCreditCard (token) {
      const data = {
        token: token,
      }

      PaymentService.callbackCreditCardPaymentMethod(data)
        .then(
          (response) => {
            this.payment.invoice = response.invoice
            this.payment.paymentMethodSelectedId = this.payment.paymentMethod.creditCard
            this.payment.complete = true
            this.payment.showInvoice = true
            // this.payment.step = 2

            this.getUserBalance()
          },
          (error) => {
            if (error.response.status === 422) {
              EventBus.$emit('showAlert', 'danger', this.$t('Error al intentar añadir plan'))
            }
            EventBus.$emit('showAlert', 'danger', this.$t(error.response.data.code))
          },
        )
        .finally(() => {})
    },
    getCreditCardPaymentMethod () {
      this.loadingCreditCard = true
      PaymentService.getCreditCardPaymentMethod()
        .then(
          (response) => {
            this.payment.paymentDetail = response
            this.payment.defaultCreditCardId = response.subscribedCards.length > 0 ? response.subscribedCards[0].id : '0'
          },
          // (error) => {
          //   PaymentService.errorResponse(
          //     error.response.status,
          //     this.$t('Error al intentar añadir plan'),
          //     this.$t(error.response.data.code),
          //   )
          // },
        )
        .finally(() => {
          this.loadingCreditCard = false
          this.confirmSubscription()
        })
    },
    subscribeNow () {
      // this.getPaymentMethods()
      this.payment.step = 2
    },
    confirmSubscription () {
      // console.log('payment', this.payment)
      // const data = {
      //   amount: this.parseAmount(this.payment.amount),
      //   infobillingId: this.payment.paymentDetail.infoBilling.billingProfiles[0].id,
      //   subscribed_card: this.payment.creditCardOptions.subscribed_card,
      //   rememberCreditCard: this.payment.creditCardOptions.rememberCreditCard,
      //   promo: this.promo,
      //   invoiceTmpId: this.payment.invoiceTmp.id,
      // }

      routesService.getCurrentUserAllRoutes()
      .then((routes) => {
        const hasRoutes = routes.length > 0
        const needsAssistant = this.isAdditional || !hasRoutes
        const data = {
          plan: Subscriptions.WHATSAPP,
          subscription: true,
          infobillingId: this.payment.paymentDetail.infoBilling?.billingProfiles[0].id,
          subscribed_card: this.payment.creditCardOptions.subscribed_card,
          promo: this.promo,
          invoiceTmpId: this.payment.invoiceTmp.id,
          pathOK: needsAssistant ? '/developers/whatsapp-assistant' : '/users/plans',
          pathKO: '/payments/whatsapp-subscription-needed',
          isAdditional: this.isAdditional,
          needsAssistant: needsAssistant,
        }
        SubscriptionPlanService.confirmSubscription(data).then(
          (response) => {
            this.payment.setup = response.setup
            this.payment.fee = response.fee
            this.payment.invoiceTmp = response.invoiceTmp
            this.payment.urlCreditCardPay = response.url
          },
          (error) => {
            console.log('error', error)
          },
        )
      })
    },
  },
  created () {
    SubscriptionPlanService.getSubscriptionPlan(Subscriptions.WHATSAPP)
    .then(plan => {
      this.setup = plan.planSetup
      this.fee = plan.planFee
    })
  },
  mounted () {
    this.isAdditional = parseInt(this.$route.query.isAdditional)
    this.getPaymentMethods()
  },
}
